/*eslint-disable */
import axios from 'axios';

let reCaptcha = {
  /**
   * createAssessment
   * Used to determine the captcha Score
   * 
   * For Recaptcha2 Add HTML to show the Recaptcha Image
   * <div :data-sitekey="YOUR_SITE_KEY" data-callback='recaptchaOnClick' data-action='submit' id="g-recaptcha" class="g-recaptcha" data-callback="recaptchaOnClick"></div>
   * 
   * On Page Mount Register the Callback fn() to be called on Captcha Click and receiove a token. In this fn() call google's createAssessment() to access ths captcha score by verifying the token
   * window.recaptchaOnClick = context.recaptchaOnClick Used for ReCaptcha2
   * 
   * https://recaptchaenterprise.googleapis.com/v1/projects/gideapp/assessments?key=API_KEY
   * GET API KEY:- https://console.cloud.google.com/apis/credentials?authuser=1&hl=en&project=gideapp
   */
  async createAssessment (context=null, YOUR_SITE_KEY=process.env.VUE_APP_GOOGLE_RECAPTCHA3_KEY, captchaToken) {
    let recaptchaObj = {
      "event": {
        "token": captchaToken,
        "expectedAction": "recaptchaVerify",
        "siteKey": YOUR_SITE_KEY,
      }
    }
    
    try {
      let api_url = `https://recaptchaenterprise.googleapis.com/v1/projects/gideapp/assessments?key=AIzaSyABXYQXOdtTLNMDAHuqR2jrklCvNwm5FN4`

      axios({
        method: "POST",
        url: api_url,
        timeout: 1000 * 60 * 30, // Wait for 60*30 seconds
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(recaptchaObj)
      })    
      .then((response) => {
        if(response.status === 200) {
          // console.log("XXXXX-recaptchaenterprise.googleapis.com Response", response)
          return(response)
        }
        else{
          if (process.env.VUE_APP_DEBUG == "true") {console.log("curl:API Error Resp:", api, ":", JSON.stringify(response.status));}  
          return({resp_status:false, resp_code:response.status, resp_msg:"Error: Network Error"});  
        }
      })
    }
    catch(err){
      console.error('Exception occurred at createAssessment() and Exception:', err)
      return("error")
    }
  }
}

export {
  reCaptcha
}

